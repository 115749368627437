<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div class="aboveTable">
      <div v-if="done_num + not_sum > 0" class="finish-performance">
        <span style="margin-right: 4px">{{ $t("test.status") }} &nbsp;</span>
        <div
          style="
            height: 15px;
            border-radius: 20px;
            background-color: rgba(255, 87, 51, 0.3);
            width: 200px;
            display: flex;
            align-items: center;
          "
        >
          <div
            v-if="done_num > 0"
            :style="
              'color:rgb(0 167 11);height:16px;border-radius: 20px;display: flex;justify-content: center;align-items: center;background-color:rgb(188,232,208);width:' +
              (done_num / (not_sum + done_num)) * 100 +
              '%'
            "
          >
            {{ done_num }}
          </div>
          <div
            v-if="not_sum > 0"
            :style="
              'color:rgba(255, 87, 51, 1);height:16px;display: flex;justify-content: center;align-items: center;width:' +
              (100 - (done_num / (not_sum + done_num)) * 100) +
              '%'
            "
          >
            {{ not_sum }}
          </div>
        </div>
      </div>
      <div v-show="is_edit" class="add_nodes">
        <!-- <span class="title">{{ $t("test.addNode.title") }}</span> -->
        <el-select
          v-model="add_nodes"
          multiple
          size="small"
          filterable
          collapse-tags
          remote
          :remote-method="get_add_nodes_options"
          :loading="add_nodes_options_loading"
          :popper-append-to-body="false"
          :placeholder="$t('test.addNode.place')"
          :reserve-keyword="true"
        >
          <el-option
            v-for="item in add_nodes_options"
            :key="item.nodeKey"
            :label="`${item.nodeKey} ${item.topic}`"
            :value="item.nodeKey"
            style="text-align: left;"
          >
            <select-icon :item="item"> </select-icon>
          </el-option>
        </el-select>
        <span>
          <el-button
            v-limits-of-authority="'NODE_EDIT'"
            :disabled="limits_of_authority_disable('NODE_EDIT')"
            type="primary"
            size="small"
            @click="get_selected_nodes"
            >{{ $t("btn.addBtn") }}</el-button
          >
        </span>
      </div>
    </div>
    <div class="test-nodes" style="flex: 1">
      <el-table
        v-loading="node_list_loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        :element-loading-text="$t('loading.loading3')"
        element-loading-spinner="el-icon-loading"
        height="90%"
        :data="current_nodes_list"
        :header-cell-style="{
          color: '#383838',
          fontWeight: 'blod',
          fontSize: '14px',
          backgroundColor: 'transparent',
        }"
        :cell-style="set_cell_style"
        @filter-change="filterChange"
      >
        <el-table-column
          :label="$t('test.checked_options.testcase')"
          align="left"
          header-align="left"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <el-tooltip
                effect="light"
                placement="top-end"
                v-ak-tooltip-auto-show
                :content="scope.row.nodeKey"
              >
                <span
                  v-limits-of-authority="'NODE_DETAIL_READ'"
                  style="
                    cursor: pointer;
                    font-weight: bold;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  @click="route_to_node_details(scope.row)"
                  >{{ scope.row.nodeKey }}</span
                >
              </el-tooltip>
              <div style="display: inline-block">
                <div
                  v-limits-of-authority="'FILE_READ'"
                  style="display: inline-block"
                >
                  <i
                    style="
                      font-size: 12px;
                      margin-left: 16px;
                      cursor: pointer;
                      color: rgb(48, 100, 143);
                    "
                    class="iconfont icon-node_link"
                    @click="route_to_node(scope.row)"
                  ></i>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.title')"
          min-width="200"
          align="left"
          header-align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              @click="show_drawer(scope.row.nodeKey)"
              >{{ scope.row.topic }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('test.nodeTable.status1')"
          align="left"
          header-align="left"
        >
          <template slot-scope="scope">
            <status-tag
              v-model="status[scope.row.status].name"
              :style_catogry="'plain'"
              :status_type="status[scope.row.status].statusCategory"
              :size="'small'"
            >
            </status-tag>
            <!-- <div style="display: flex; justify-content: left">
              <el-tooltip effect="dark" placement="top" :disabled="!isShowTooltip">
                <div slot="content">{{scope.row.status}}</div>
                <div :class="{
                  status: true,
                  status_todo: scope.row.statusCategory == 'TODO',
                  status_progress: scope.row.statusCategory == 'IN_PROGRESS',
                  status_open: scope.row.statusCategory == 'DONE',
                }" @mouseenter="visibilityChange($event)">
                  <span style="white-space: nowrap;">{{ scope.row.status }}</span>
                </div>
              </el-tooltip>
            </div> -->
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.result')"
          show-overflow-tooltip
          min-width="150"
          prop="testResult"
          align="left"
          header-align="left"
        >
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.status')"
          align="left"
          header-align="left"
          :filters="filtersList"
        >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: left">
              <div class="excecute_status">
                {{ execution_status[scope.row.testCaseStatus].statusName }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="is_edit" align="left" header-align="left">
          <template slot-scope="scope">
            <i
              v-limits-of-authority="'NODE_EDIT'"
              style="cursor: pointer; color: #f56c6c; font-weight: 700"
              class="iconfont icon-baseline-delete"
              @click="delete_test_node(scope.row.nodeKey)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
      <el-pagination
      :page-size="pageSize"
      :pager-count="pageCount"
      :current-page.sync="pageCurrent"
      class="header-pagination"
      style="text-align: end;margin-top:20px"
      :background="false"
      small
      layout="prev, pager, next"
      :total="filtersData.length"
      >
    </el-pagination>
  </div>
</template>

<script>
import { get_nodes_by_fuzzykey_testCases } from "@/network/node/index.js";
import StatusTag from "@/components/statusTag";
import {
  add_test_node,
  get_test_nodes,
  delete_test_node,
} from "@/network/test/index.js";
import { getFile } from "@/network/home/index.js";
import { get_file } from "@/network/baseline/index.js";
import selectIcon from "@/components/selectIcon";

import { limits_of_authority_disable } from "@/utils/limits";
import { mapGetters } from "vuex";
export default {
  name: "TestNotStart",
  components: {
    StatusTag,
    selectIcon,
  },
  props: {
    cur_test_id: {
      type: String,
      default: "",
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    relate_versions: {
      type: Array,
      default: () => [],
    },
    update_node: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      node_list_loading: true,
      // 表格节点列表
      nodes_list: [],
      // 添加节点
      add_nodes: [],
      add_nodes_options: [],
      add_nodes_options_loading: false,
      isShowTooltip: false,
      pageSize: 20,
      pageCount: 5,
      pageCurrent: 1,
      current_nodes_list: [],
      filtersData: [],
      selectedFilter: [],
      done_num: 0,
      not_sum: 0,
    };
  },

  computed: {
    ...mapGetters(["execution_status", "status"]),
    filtersList(){
      let allExecutionStatus = this.$store.getters.execution_status
      let list = []
      for(let item of Object.values(allExecutionStatus)){
        list.push({ text: item.statusName, value: item.status })
      }
      return list
    },
  },
  watch: {
    cur_test_id(new_test_id, old_test_test_id) {
      this.node_list_loading = true;
      this.get_test_nodes();
    },
    relate_versions(new_versions, old) {
      this.node_list_loading = true;
      //TestInfo的待测版本更新时,对测试用例节点列表进行更新
      get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
        this.nodes_list = res;
        this.node_list_loading = false;
      });
    },
    update_node(newVal) {
      if (!newVal) {
        this.node_list_loading = true;
         get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
          this.nodes_list = res;
          this.node_list_loading = false;
        });
      }
    },
    pageCurrent:{
      handler(newv, oldv){
        this.current_nodes_list = this.filtersData.slice((newv-1)*this.pageSize,newv*this.pageSize)
      },
    },
    nodes_list:{
      handler(newv, oldv){
        this.filtersData = newv
        this.current_nodes_list = this.filtersData.slice((this.pageCurrent-1)*this.pageSize,this.pageCurrent*this.pageSize)
      },
      immediate:true
    },
  },
  created() {},

  mounted: function () {
    //获取待测版本下的节点信息
    //获取该测试计划的测试用例
    this.get_test_nodes();
  },
  methods: {
    limits_of_authority_disable,
    show_drawer(row) {
      this.$emit("show_drawer", row);
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    get_test_nodes() {
      this.node_list_loading = true;
      get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
        this.nodes_list = res;
        this.compute_not_yet();
        this.node_list_loading = false;
      });
    },
     //计算未完成数量
    compute_not_yet() {
      let not_yet_num = [];
      this.nodes_list.forEach((e) => {
        if (!this.execution_status[e.testCaseStatus].complete) {
          not_yet_num.push(e);
        }
      });
      this.not_sum = not_yet_num.length;
      this.done_num = this.nodes_list.length - this.not_sum;
    },
    // 点击节点key跳转到节点详情
    route_to_node_details(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    // 点击节点key旁的图标跳转到节点在图中的位置
    route_to_node(row) {
      get_file(this.get_pid(), row.fileId).then((result) => {
        getFile(this.get_pid(), result.key)
          .then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", res.fileId);
            this.$store.commit("SET_FILE_KEY", res.fileKey);
            this.$store.commit("SET_MINDMAP_NAME", res.fileName);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", res.fileTypeId);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${result.key}`,
              query: {
                node_id: row.id, // 节点id
              },
            });
            window.open(href, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    //删除测试计划下的测试用例
    delete_test_node(nodeKey) {
      this.node_list_loading = true;
      delete_test_node(this.get_pid(), this.cur_test_id, nodeKey).then(
        (res) => {
          this.get_test_nodes();
        }
      );
    },
    // 给节点表格设置样式
    set_cell_style({ columnIndex }) {
      let style = "";
      if (columnIndex == 0) {
        style += "color:rgba(255, 195, 0, 1);";
      } else {
        style += "color:#636363;";
      }
      return style;
    },
    // 获得模糊搜索的节点列表
    get_add_nodes_options(fuzzyKey) {
      this.add_nodes_options_loading = true;
      get_nodes_by_fuzzykey_testCases(this.get_pid(), fuzzyKey).then((res) => {
        this.add_nodes_options_loading = false;
        this.add_nodes_options = res.filter((item) => {
          let flag = true;
          this.nodes_list.forEach((node) => {
            if (node.nodeKey === item.nodeKey) {
              flag = false;
            }
          });
          return flag;
        });
      });
    },
    //获取要添加的测试用例节点
    get_selected_nodes() {
      this.node_list_loading = true;
      add_test_node(this.get_pid(), this.add_nodes, this.cur_test_id).then(
        (res) => {
          this.get_test_nodes();
        }
      );
      this.add_nodes = [];
    },
    filterChange(filters){
      this.pageCurrent = 1
      this.selectedFilter = Object.values(Object.values(filters))[0]
      if(this.selectedFilter.length>0){
        let selected = []
        this.nodes_list.forEach((item)=>{
          if(this.selectedFilter.indexOf(item.testCaseStatus) != -1){
            selected.push(item);
          }
        })
        this.filtersData = selected;
        this.current_nodes_list = this.filtersData.slice((this.pageCurrent-1)*this.pageSize,this.pageCurrent*this.pageSize)
      }else{
        this.filtersData = this.nodes_list
        this.current_nodes_list = this.filtersData.slice((this.pageCurrent-1)*this.pageSize,this.pageCurrent*this.pageSize)
      }
      
    }
  },
};
</script>

<style lang="scss" scoped>
.test-nodes {
  padding: 8px 0;
  margin-top: 17px;
  border-radius: 8px;
  background-color: #fff;
  height: 80%;
  .excecute_status {
    padding-left: 5px;
    padding-right: 5px;
    width: 80px;
    height: 22px;
    text-align: center;
    background: rgba(255, 195, 0, 1);
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
  }
  ::v-deep .el-table__row {
    td:nth-child(2) {
      > div {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.add_nodes {
  text-align: end;
  ::v-deep .el-select {
    .el-tag:first-child {
      display: flex;
      align-items: center;
      .el-select__tags-text {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }
  .title {
    font-size: 14px;
    color: rgba(144, 147, 153, 1);
    margin-right: 5px;
  }
  ::v-deep .el-select-dropdown {
    // 解决下拉框错位
    position: absolute !important;
    // top: -277px !important;
    left: 0 !important;
    max-width: 400px;
    .popper__arrow {
      display: none;
    }
  }
  ::v-deep .el-input {
    width: 318px;
    margin-right: 12px;
  }
}

// 按钮的样式
::v-deep.el-button {
  font-size: 16px;
  padding: 6px 10px;
}
</style>
<style lang="scss" scoped>
.status {
  margin-left: 5px;
  width: 60px;
  height: 25px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.status_todo {
  color: rgba(255, 195, 0, 1);
  border: 1px solid rgba(255, 195, 0, 1);
}
.status_open {
  color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress {
  color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
::v-deep .el-pagination {
  .btn-prev{
    background: transparent !important;
  }
  .btn-next{
    background: transparent !important;
  }
  li{
    background: transparent !important;
  }
}
.aboveTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.aboveTable > :only-child {
  margin-left: auto; /* 使唯一的子元素靠右 */
}
.finish-performance {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 16px;
  color: rgba(128, 128, 128, 1);
  margin: 10px 0;
}
</style>
