<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div class="aboveTable">
      <div v-if="done_num + not_sum > 0" class="finish-performance">
        <span style="margin-right: 4px">{{ $t("test.status") }} &nbsp;</span>
        <div
          style="
            height: 15px;
            border-radius: 20px;
            background-color: rgba(255, 87, 51, 0.3);
            width: 200px;
            display: flex;
            align-items: center;
          "
        >
          <div
            v-if="done_num > 0"
            :style="
              'color:rgb(0 167 11);height:16px;border-radius: 20px;display: flex;justify-content: center;align-items: center;background-color:rgb(188,232,208);width:' +
              (done_num / (not_sum + done_num)) * 100 +
              '%'
            "
          >
            {{ done_num }}
          </div>
          <div
            v-if="not_sum > 0"
            :style="
              'color:rgba(255, 87, 51, 1);height:16px;display: flex;justify-content: center;align-items: center;width:' +
              (100 - (done_num / (not_sum + done_num)) * 100) +
              '%'
            "
          >
            {{ not_sum }}
          </div>
        </div>
      </div>
      <div v-show="is_edit" class="add_nodes">
        <!-- <span class="title">{{ $t("test.addNode.title") }}</span> -->
        <el-select
          v-model="add_nodes"
          multiple
          size="small"
          filterable
          collapse-tags
          remote
          :remote-method="get_add_nodes_options"
          :loading="add_nodes_options_loading"
          :popper-append-to-body="false"
          :placeholder="$t('test.addNode.place')"
          :reserve-keyword="true"
        >
          <el-option
            v-for="item in add_nodes_options"
            :key="item.nodeKey"
            :label="`${item.nodeKey} ${item.topic}`"
            :value="item.nodeKey"
            style="text-align: left"
          >
            <select-icon :item="item"> </select-icon>
          </el-option>
        </el-select>
        <span>
          <el-button
            v-limits-of-authority="'NODE_EDIT'"
            :disabled="limits_of_authority_disable('NODE_EDIT')"
            type="primary"
            size="small"
            @click="get_selected_nodes"
            >{{ $t("btn.addBtn") }}</el-button
          >
        </span>
      </div>
    </div>
    <div class="test-nodes" style="flex: 1">
      <el-table
        v-loading="node_list_loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        :element-loading-text="$t('loading.loading3')"
        element-loading-spinner="el-icon-loading"
        :data="current_nodes_table"
        :header-cell-style="{
          color: '#383838',
          fontWeight: 'blod',
          fontSize: '14px',
          backgroundColor: 'transparent',
        }"
        :cell-style="set_cell_style"
        height="90%"
        @filter-change="filterChange"
      >
        <el-table-column
          label="Key"
          align="left"
          header-align="left"
          min-width="100"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <el-tooltip
                effect="light"
                placement="top-end"
                v-ak-tooltip-auto-show
                :content="scope.row.nodeKey"
              >
                <span
                  v-limits-of-authority="'NODE_DETAIL_READ'"
                  style="
                    cursor: pointer;
                    font-weight: bold;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  @click="route_to_node_details(scope.row)"
                  >{{ scope.row.nodeKey }}</span
                >
              </el-tooltip>
              <div style="display: inline-block">
                <div
                  v-limits-of-authority="'FILE_READ'"
                  style="display: inline-block"
                >
                  <i
                    style="
                      font-size: 12px;
                      margin-left: 16px;
                      cursor: pointer;
                      color: rgb(48, 100, 143);
                    "
                    class="iconfont icon-node_link"
                    @click="route_to_node(scope.row)"
                  ></i>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.title')"
          align="left"
          min-width="200"
          header-align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              @click="show_drawer(scope.row.nodeKey)"
              >{{ scope.row.topic }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.result')"
          show-overflow-tooltip
          min-width="150"
          prop="testResult"
          align="left"
          header-align="left"
        >
        </el-table-column>
        <el-table-column
          :label="$t('test.attachments')"
          align="left"
          header-align="left"
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-upload"
              type="info"
              style="border: none !important"
              size="small"
              @click="show_file_dialog(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.status')"
          align="left"
          header-align="left"
          :filters="filtersList"
        >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: left">
              <el-tooltip
                effect="dark"
                placement="top"
                :disabled="!isShowTooltip"
              >
                <div slot="content">
                  {{ execution_status[scope.row.testCaseStatus].statusName }}
                </div>
                <div
                  class="excecute_status"
                  :style="
                    'background-color:' +
                    execution_status[scope.row.testCaseStatus].color
                  "
                  @mouseenter="visibilityChange($event)"
                >
                  <span style="word-break: keep-all !important">{{
                    execution_status[scope.row.testCaseStatus].statusName
                  }}</span>
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.bug')"
          align="left"
          header-align="left"
          min-width="150"
        >
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.bugNodes"
              :key="item.nodeKey"
              style="
                display: flex;
                justify-content: left;
                align-items: center;
                padding: 5px;
              "
            >
              <div
                v-limits-of-authority="'NODE_DETAIL_READ'"
                class="bug_node_font"
                style="font-weight: 600; cursor: pointer"
                @click="route_to_node_details(item)"
              >
                {{ item.nodeKey }}
              </div>
              <el-tooltip v-ak-tooltip-auto-show effect="dark" placement="top">
                <div slot="content">{{ status[item.status].name }}</div>
                <div
                  v-if="scope.row.test_result != $t('test.notExecuted')"
                  :class="{
                    status: true,
                    status_todo: status[item.status].statusCategory == 'TODO',
                    status_progress:
                      status[item.status].statusCategory == 'IN_PROGRESS',
                    status_open: status[item.status].statusCategory == 'DONE',
                  }"
                >
                  <span style="white-space: nowrap">{{
                    status[item.status].name
                  }}</span>
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="executor"
          :label="$t('test.nodeTable.executor')"
          align="left"
          header-align="left"
        >
          <template slot-scope="scope">
            <span class="topic">{{
              matchUserNickname(scope.row.executor)
            }}</span></template
          >
        </el-table-column>
        <!-- <el-table-column
          v-if="is_edit"
          min-width="80"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <svg-icon
              style="cursor: pointer"
              icon-class="node_delete"
              @click="delete_test_node(scope.row.nodeKey)"
            ></svg-icon>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div style="margin-top: 20px">
      <el-pagination
        :page-size="pageSize"
        :pager-count="pageCount"
        :current-page.sync="pageCurrent"
        class="header-pagination"
        style="text-align: end"
        :background="false"
        small
        layout="prev, pager, next"
        :total="filtersData.length"
      >
      </el-pagination>
    </div>

    <el-dialog
      id="dialogidz"
      :visible.sync="is_show_file_dialog"
      top="15vh"
      width="50%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <span
        slot="title"
        style="font-size: 16px; font-weight: bold; padding-left: 10px"
      >
        {{ $t("test.attachments") }}
      </span>

      <el-table
        :data="file_dialog_list"
        style="width: 100%"
        height="50vh"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
      >
        <el-table-column :label="$t('test.setStatus.fileName')" align="left">
          <template slot-scope="scope">
            <span> {{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('test.setStatus.option')" width="200">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: flex-start">
              <el-button
                v-if="isImg(scope.row)"
                icon="el-icon-view"
                type="info"
                size="small"
                style="border: none !important"
                @click="see_img(config.oss_url + scope.row.url)"
              >
              </el-button>
              <el-button
                v-if="isPDF(scope.row)"
                icon="el-icon-view"
                type="info"
                size="small"
                style="border: none !important"
                @click="see_pdf(config.oss_url + scope.row.url)"
              >
              </el-button>
              <el-button
                type="info"
                size="small"
                icon="el-icon-download"
                style="border: none !important; margin-right: 15px"
                @click="download_file(config.oss_url + scope.row.url)"
              >
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="$t('addFileList.title1')"
        :visible.sync="dialogImgVisible"
        :close-on-click-modal="false"
        :show-close="true"
        width="50%"
        append-to-body
      >
        <el-image
          :src="currentSrc"
          style="width: 100%; height: 100%"
          :preview-src-list="imgList"
        >
        </el-image>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>

      <el-dialog
        :title="$t('addFileList.title2')"
        :visible.sync="dialogPdfVisible"
        :close-on-click-modal="false"
        :show-close="true"
        width="80%"
        :top="'1vh'"
        append-to-body
      >
        <iframe :src="currentSrc" style="width: 100%; height: 800px"></iframe>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="is_show_file_dialog = false">{{
          $t("btn.closeBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get_nodes_by_fuzzykey_testCases } from "@/network/node/index.js";
import {
  add_test_node,
  get_test_nodes,
  delete_test_node,
} from "@/network/test/index.js";
import { getFile } from "@/network/home/index.js";
import { mapGetters } from "vuex";
import selectIcon from "@/components/selectIcon";
import { get_file } from "@/network/baseline/index.js";
import config from "@/../config/index";
import { limits_of_authority_disable } from "@/utils/limits";
export default {
  name: "TestInProcess",
  components: {
    selectIcon,
  },
  props: {
    cur_test_id: {
      type: String,
      default: "",
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    relate_versions: {
      type: Array,
      default: () => [],
    },
    update_node: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowTooltip: false,
      nodes_list: [],
      // 添加节点
      add_nodes: [],
      add_nodes_options: [],
      add_nodes_options_loading: false,
      done_num: 0,
      not_sum: 0,
      node_list_loading: true,
      is_dialog_visible: false,
      is_show_file_dialog: false,
      dialogPdfVisible: false,
      dialogImgVisible: false,
      current_row: "",
      currentSrc: "",
      imgList: [],
      file_dialog_list: [],
      config: config,
      pageSize: 20,
      pageCount: 5,
      pageCurrent: 1,
      current_nodes_table: [],
      filtersData: [],
      selectedFilter: [],
    };
  },
  computed: {
    ...mapGetters(["execution_status", "status"]),
    name() {
      return this.$store.getters.name;
    },
    filtersList() {
      let allExecutionStatus = this.$store.getters.execution_status;
      let list = [];
      for (let item of Object.values(allExecutionStatus)) {
        list.push({ text: item.statusName, value: item.status });
      }
      return list;
    },
  },
  watch: {
    cur_test_id() {
      this.get_test_nodes();
    },
    update_node(newVal) {
      if (!newVal) {
        this.node_list_loading = true;
        get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
          this.nodes_list = res;
          this.node_list_loading = false;
        });
      }
    },
    pageCurrent: {
      handler(newv, oldv) {
        this.current_nodes_table = this.filtersData.slice(
          (newv - 1) * this.pageSize,
          newv * this.pageSize
        );
      },
    },
    nodes_list: {
      handler(newv, oldv) {
        this.filtersData = newv;
        this.current_nodes_table = this.filtersData.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.get_test_nodes();
  },
  methods: {
    limits_of_authority_disable,
    show_drawer(row) {
      this.$emit("show_drawer", row);
    },
    download_file(url) {
      window.open(url);
    },
    show_file_dialog(row) {
      this.is_show_file_dialog = true;
      this.file_dialog_list = row.attachments;
      this.current_row = row;
    },
    isPDF(file) {
      return file.name.endsWith(".pdf") || file.name.endsWith(".PDF");
    },
    isImg(file) {
      return (
        file.name.endsWith(".png") ||
        file.name.endsWith(".jpg") ||
        file.name.endsWith(".jpeg") ||
        file.name.endsWith(".PNG") ||
        file.name.endsWith(".JPG") ||
        file.name.endsWith(".JPEG")
      );
    },
    see_img(url) {
      this.dialogImgVisible = true;
      this.currentSrc = url;
      this.imgList = [];
      this.current_row.attachments.forEach((file) => {
        if (this.isImg(file)) {
          this.imgList.push(config.oss_url + file.url);
        }
      });
    },
    see_pdf(url) {
      this.dialogPdfVisible = true;
      this.currentSrc = url;
    },
    // 点击节点key跳转到节点详情
    route_to_node_details(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    // 点击节点key旁的图标跳转到节点在图中的位置
    route_to_node(row) {
      get_file(this.get_pid(), row.fileId).then((result) => {
        getFile(this.get_pid(), result.key)
          .then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", res.fileId);
            this.$store.commit("SET_FILE_KEY", res.fileKey);
            this.$store.commit("SET_MINDMAP_NAME", res.fileName);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", res.fileTypeId);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${result.key}`,
              query: {
                node_id: row.id, // 节点id
              },
            });
            window.open(href, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    get_test_nodes() {
      this.node_list_loading = true;
      get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
        this.nodes_list = res;
        this.compute_not_yet();
        this.node_list_loading = false;
      });
    },
    // 删除测试计划下的测试用例
    delete_test_node(nodeKey) {
      this.node_list_loading = true;
      delete_test_node(this.get_pid(), this.cur_test_id, nodeKey).then(
        (res) => {
          this.get_test_nodes();
        }
      );
    },
    //计算未完成数量
    compute_not_yet() {
      let not_yet_num = [];
      this.nodes_list.forEach((e) => {
        if (!this.execution_status[e.testCaseStatus].complete) {
          not_yet_num.push(e);
        }
      });
      this.not_sum = not_yet_num.length;
      this.done_num = this.nodes_list.length - this.not_sum;
    },

    get_selected_nodes() {
      this.node_list_loading = true;
      add_test_node(this.get_pid(), this.add_nodes, this.cur_test_id).then(
        (res) => {
          this.get_test_nodes();
        }
      );
      this.add_nodes = [];
    },
    // 给节点表格设置样式
    set_cell_style({ columnIndex }) {
      let style = "";
      if (columnIndex == 0) {
        style += "color:rgba(255, 195, 0, 1);";
      } else {
        style += "color: #636363;";
      }
      return style;
    },
    // 获得模糊搜索的节点列表
    get_add_nodes_options(fuzzyKey) {
      this.add_nodes_options_loading = true;
      get_nodes_by_fuzzykey_testCases(this.get_pid(), fuzzyKey).then((res) => {
        this.add_nodes_options_loading = false;
        this.add_nodes_options = res.filter((item) => {
          let flag = true;
          this.nodes_list.forEach((node) => {
            if (node.nodeKey === item.nodeKey) {
              flag = false;
            }
          });
          return flag;
        });
      });
    },
    filterChange(filters) {
      this.pageCurrent = 1;
      this.selectedFilter = Object.values(Object.values(filters))[0];
      if (this.selectedFilter.length > 0) {
        let selected = [];
        this.nodes_list.forEach((item) => {
          if (this.selectedFilter.indexOf(item.testCaseStatus) != -1) {
            selected.push(item);
          }
        });
        this.filtersData = selected;
        this.current_nodes_table = this.filtersData.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      } else {
        this.filtersData = this.nodes_list;
        this.current_nodes_table = this.filtersData.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.finish-performance {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 16px;
  color: rgba(128, 128, 128, 1);
  margin: 10px 0;
}
.test-nodes {
  margin-top: 8px;
  padding: 8px 0;
  border-radius: 8px;
  background-color: #fff;
  ::v-deep .el-table tr td:nth-child(3) .cell {
    white-space: pre-line;
  }
  .el-table__body-wrapper {
    overflow: hidden !important;
  }
  .excecute_status {
    // display: flex;
    width: 80px;
    height: 25px;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
    // align-items: center;
    // justify-content: center;
    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ::v-deep .el-table__row {
    td:nth-child(2) {
      > div {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.add_nodes {
  text-align: end;
  // margin-top: 18px;
  ::v-deep .el-select {
    .el-tag:first-child {
      display: flex;
      align-items: center;
      .el-select__tags-text {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }
  .title {
    font-size: 14px;
    color: rgba(144, 147, 153, 1);
    margin-right: 5px;
  }
  ::v-deep .el-select-dropdown {
    // 解决下拉框错位
    position: absolute !important;
    // top: -277px !important;
    left: 0 !important;
    max-width: 400px;
    .popper__arrow {
      display: none;
    }
  }
  ::v-deep .el-input {
    width: 318px;
    margin-right: 12px;
  }
}
</style>
<style lang="scss" scoped>
.status {
  margin-left: 5px;
  width: 90px;
  height: 25px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.status_todo {
  border: 1px solid rgba(166, 166, 166, 1);
  color: rgba(166, 166, 166, 1);
}
.status_open {
  color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress {
  color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
.bug_node_font {
  height: 14px;
  color: rgba(38, 50, 129, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}
.aboveTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.aboveTable > :only-child {
  margin-left: auto; /* 使唯一的子元素靠右 */
}

::v-deep .el-pagination {
  .btn-prev {
    background: transparent !important;
  }
  .btn-next {
    background: transparent !important;
  }
  li {
    background: transparent !important;
  }
}
</style>
<style lang="scss">
#dialogidz {
  .el-dialog__body {
    padding-top: 0 !important;
  }
}
</style>
