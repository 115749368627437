<template>
  <div @mouseenter="summaryOnmouserEnter" @mouseleave="summaryOnmouserLeave">
    <el-collapse
      v-model="activeNames"
      class="scroll_wrap"
      @change="handleChange"
    >
      <el-collapse-item class="collapse_item" name="1">
        <div slot="title" class="collapse-title">
          {{ $t(`nodeDetail.basicInfo.title`) }}
        </div>
        <div ref="base-info" class="test-info">
          <div class="test-info-row1" style="width: 100%">
            <div class="baseline-and-version" style="width: 100%">
              <div class="baseline">
                <span class="title">{{ $t("test.basicInfo.baseline") }}: </span>
                <el-select
                  v-if="is_edit && test_status == 'NOT_STARTED'"
                  v-model="baseline"
                  multiple
                  filterable
                  :placeholder="$t('placeholder.dropdown')"
                  size="small"
                  style="width: 200px"
                  @change="edit_test_plan"
                >
                  <el-option
                    v-for="item in baseline_options"
                    :key="item.baselineId"
                    :label="item.name"
                    :value="item.baselineId"
                  >
                  </el-option>
                </el-select>
                <span v-else class="color-blue">
                  <span
                    v-for="(item, index) in baseline_name_list"
                    :key="index"
                    @click="jump_to_baseline(item)"
                    >{{ item.name
                    }}<i v-if="index !== baseline_name_list.length - 1">，</i>
                  </span>
                </span>
              </div>
              <div class="version">
                <span class="title">
                  <span>
                    <el-tooltip
                      effect="dark"
                      :content="$t('test.basicInfo.info')"
                    >
                      <i
                        v-if="test_status === 'NOT_STARTED' && !is_excecuting"
                        style="cursor: pointer"
                        class="el-icon-refresh"
                        @click="refresh_table_data"
                      ></i>
                    </el-tooltip>
                  </span>
                  {{ $t("test.basicInfo.version") }}:
                </span>
                <el-select
                  v-if="is_edit && test_status == 'NOT_STARTED'"
                  v-model="version"
                  multiple
                  filterable
                  :placeholder="$t('placeholder.dropdown')"
                  size="small"
                  style="width: 200px"
                  @change="edit_test_plan($event, true)"
                >
                  <el-option
                    v-for="item in version_options"
                    :key="item.versionId"
                    :label="item.name"
                    :value="item.versionId"
                  >
                  </el-option>
                </el-select>
                <span v-else class="color-blue">
                  <span
                    v-for="(item, index) in version_name_list"
                    :key="index"
                    @click="jump_to_version(item)"
                    >{{ item.name
                    }}<i v-if="index !== version_name_list.length - 1">，</i>
                  </span>
                </span>
              </div>
              <div class="responsible">
                <span class="title"
                  >{{ $t("test.basicInfo.responsibility") }}:
                </span>
                <el-select
                  v-if="is_edit && test_status == 'NOT_STARTED'"
                  v-model="responsible"
                  filterable
                  size="small"
                  @change="edit_test_plan"
                >
                  <el-option
                    v-for="item in userList"
                    v-show="!item.deleted"
                    :key="item.accountId"
                    :label="item.nickname"
                    :value="item.accountId"
                  ></el-option>
                </el-select>
                <span v-else>{{ responsible_name }}</span>
              </div>
            </div>
          </div>
          <div class="test-info-row1" style="width: 100%">
            <div class="time-and-time" style="width: 100%">
              <div class="responsible">
                <span class="title"
                  >{{ $t("test.basicInfo.planStartTime") }}:</span
                >
                <el-date-picker
                  v-if="is_edit && test_status == 'NOT_STARTED'"
                  v-model="plannedStartTime"
                  size="small"
                  style="width: 200px"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  :placeholder="$t('addMindMap.tip7')"
                  @change="edit_test_plan"
                >
                </el-date-picker>
                <span v-else>{{ plannedStartTime }}</span>
              </div>
              <div class="responsible">
                <span class="title"
                  >{{ $t("test.basicInfo.plannedEndTime") }}:</span
                >
                <el-date-picker
                  v-if="is_edit && test_status == 'NOT_STARTED'"
                  v-model="plannedEndTime"
                  size="small"
                  style="width: 200px"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  :placeholder="$t('addMindMap.tip7')"
                  @change="edit_test_plan"
                >
                </el-date-picker>
                <span v-else>{{ plannedEndTime }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="test_status !== 'NOT_STARTED'"
            class="test-info-row1"
            style="width: 100%"
          >
            <div class="time-and-time" style="width: 100%">
              <div class="responsible">
                <span class="title"
                  >{{ $t("test.basicInfo.actualStartTime") }}:</span
                >
                <span>{{ actualStartTime }}</span>
              </div>
              <div class="responsible">
                <span class="title"
                  >{{ $t("test.basicInfo.actualEndTime") }}:</span
                >
                <span>{{ actualEndTime }}</span>
              </div>
            </div>
          </div>
          <div class="test-target">
            <div
              class="title"
              :style="is_edit ? 'width:100px;' : 'min-width:100px;'"
            >
              {{ $t("test.basicInfo.goal") }}:
            </div>
            <el-input
              v-if="is_edit && test_status == 'NOT_STARTED'"
              v-model="test_target"
            ></el-input>
            <el-scrollbar v-else style="max-height: 90px; width: 100%">
              <span class="description">{{ test_target }}</span>
            </el-scrollbar>
          </div>
          <div class="remark">
            <span
              class="title"
              :style="is_edit ? 'width:100px;' : 'min-width:100px;'"
              >{{ $t("test.basicInfo.note") }}:
            </span>
            <el-input
              v-if="is_edit"
              v-model="remark"
              size="small"
              type="textarea"
              :autosize="{ maxRows: 4 }"
              @blur="edit_test_plan"
            >
            </el-input>
            <el-scrollbar v-else style="max-height: 80px; flex: 1">
              <span class="description" v-html="newDescription"></span>
            </el-scrollbar>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { get_allProjectMember } from "@/network/node/index.js";
import {
  edit_test_plan,
  refreshTestCaseFromVersion,
} from "@/network/testPlan/index.js";
import bus from "./testBus.js";
export default {
  name: "TestInfo",
  props: {
    test_plan: {
      type: Object,
      default: () => {},
    },
    is_auto_save: {
      type: Boolean,
      default: false,
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    // 当前测试计划是什么状态：0-未开始；1-执行中；2-已完成
    test_status: {
      type: String,
      default: "",
    },
    is_excecuting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 参考基线
      baseline: [],
      baseline_options: [],
      // 待测版本
      version: [],
      version_options: [],
      // 测试负责人
      responsible: "",
      // 测试目标
      test_target: "",
      // 备注
      remark: "",
      // 项目成员列表
      userList: [],
      des_max_height: "90px",
      plannedStartTime: "",
      plannedEndTime: "",
      actualStartTime: "",
      actualEndTime: "",
      newDescription: "",
      activeNames: [],
    };
  },
  computed: {
    cur_test_id() {
      return this.test_plan.testPlanId;
    },
    // 用于非编辑模式显示的参考基线名称
    baseline_name_list() {
      const temp = [];
      this.baseline.forEach((baselineId) => {
        if (
          this.baseline_options.find((item) => item.baselineId == baselineId)
        ) {
          temp.push(
            this.baseline_options.find((item) => item.baselineId == baselineId)
          );
        }
      });
      return temp;
    },
    // 用于非编辑模式显示的待测版本名称列表
    version_name_list() {
      const temp = [];
      this.version.forEach((versionId) => {
        if (this.version_options.find((item) => item.versionId == versionId)) {
          temp.push(
            this.version_options.find((item) => item.versionId == versionId)
          );
        }
      });

      return temp;
    },
    responsible_name() {
      return this.matchUserNickname(this.responsible, "");
    },
  },
  watch: {
    is_edit(val) {
      if (val) {
        this.activeNames = ["1"];
      } else {
        this.activeNames = [];
      }
    },
    test_plan: {
      handler: function () {
        if (this.test_plan) {
          if (this.test_plan.baselines) {
            const temp = [];
            this.test_plan.baselines.forEach((item) => {
              temp.push(item.baselineId);
            });
            this.baseline = temp;
          } else {
            this.baseline = [];
          }
          if (this.test_plan.versions) {
            const temp = [];
            this.test_plan.versions.forEach((item) => {
              temp.push(item.versionId);
            });
            this.version = temp;
          } else {
            this.version = [];
          }
          this.responsible = this.test_plan.principal;
          this.test_target = this.test_plan.target;
          this.plannedStartTime = this.test_plan.plannedStartTime;
          this.plannedEndTime = this.test_plan.plannedEndTime;
          this.actualStartTime = this.test_plan.actualStartTime;
          this.actualEndTime = this.test_plan.actualEndTime;
          this.remark = this.test_plan.remark;
          this.change_description();
        }
      },
      immediate: true,
    },
  },
  created() {
    get_allProjectMember(this.get_pid()).then((res) => {
      this.userList = res;
    });
    this.baseline_options = Object.values(
      JSON.parse(sessionStorage.getItem("_baseLine"))
    );
    this.version_options = Object.values(
      JSON.parse(sessionStorage.getItem("_version"))
    );
    this.$emit("get_current_version", this.versionId);
  },
  methods: {
    summaryOnmouserEnter() {
      this.timeOut = setTimeout(() => {
        if (this.activeNames.indexOf("1") == -1 && !this.is_edit) {
          this.activeNames.push("1");
        }
      }, 500);
    },
    summaryOnmouserLeave() {
      clearTimeout(this.timeOut);
      if (this.activeNames.indexOf("1") !== -1 && !this.is_edit) {
        this.activeNames.splice(this.activeNames.indexOf("1"), 1);
      }
    },
    refresh_table_data() {
      const params = {
        projectId: this.get_pid(),
        testPlanId: this.cur_test_id,
      };
      refreshTestCaseFromVersion(params).then((res) => {
        this.$emit("refresh_table_data");
      });
    },
    jump_to_baseline(item) {
      this.$router.push({
        name: "baseline",
        params: {
          cur_id: item.baselineId,
        },
      });
    },
    jump_to_version(item) {
      this.$router.push({
        name: "version",
        params: item,
      });
    },
    // 编辑测试计划信息
    edit_test_plan(event, flag) {
      this.$emit("update:is_auto_save", true);
      this.change_description();
      edit_test_plan(this.get_pid(), this.cur_test_id, {
        name: this.test_plan.name,
        baselineIds: this.baseline,
        versionIds: this.version,
        principal: this.responsible,
        plannedStartTime: this.plannedStartTime,
        plannedEndTime: this.plannedEndTime,
        target: this.test_target,
        remark: this.remark,
      }).then((res) => {
        this.$emit("auto-save");
        if (flag) {
          this.$emit("change_versions", this.version);
        }
      });
    },
    change_description() {
      let arrstr = this.remark || "";
      this.newDescription = arrstr.replace(/\n/g, "<br>");
    },
    handleChange() {},
  },
};
</script>

<style lang="scss" scoped>
// .scroll_wrap {
//   overflow-y: scroll;
//   padding-top: 20px;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
.collapse_item {
  margin-bottom: 10px;
  margin-top: 17px;
  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
::v-deep .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-collapse-item__header {
  border-radius: 8px;
}
.description {
  display: inline-block;
  text-align: left;
  word-wrap: break-word;
  width: 100%;
}
.collapse_item .test-info {
  padding: 17px 23px 0px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
  color: rgba(128, 128, 128, 1);
  &-row1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .time-and-time {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 24px;
      & > div {
        min-width: 30%;
        text-align: left;
      }
    }
    .baseline-and-version {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      line-height: 20px;
      & > div {
        min-width: 30%;
        text-align: left;
      }
      .baseline {
        width: 30%;
        text-align: left;
        margin-bottom: 24px;
      }
      .version {
        margin-bottom: 24px;
      }
      .responsible {
        margin-bottom: 24px;
      }
    }
  }
  .test-target {
    display: flex;
    margin-bottom: 24px;
  }

  .remark {
    display: flex;
    text-align: start;
    word-break: break-all;
  }
  .title {
    flex: none;
    text-align: start;
    font-weight: bold;
    display: inline-block;
    min-width: 160px;
  }
  .color-blue {
    color: rgba(42, 130, 228, 1);
    cursor: pointer;
  }
}
// 滚动不显示横向
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
</style>
